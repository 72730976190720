<template>
    <footer>
        <div class="container">
            <div id="footer_enlace_mapa">
                <a href="https://www.google.com/maps/place/Calle+Ferraz,+56,+28008+Madrid/@40.427852,-3.7208289,17z/data=!3m1!4b1!4m5!3m4!1s0xd42286bf9a6eb0b:0x1132406b1edc2483!8m2!3d40.4278479!4d-3.7186402" target="_blank">
                    <img src="../assets/commons/banner-footer.png" />
                </a>
            </div>
            <div id="footer_direccion_falcon">
                <h3>Visita nuestra tienda <br />a pie de calle.</h3>
                <span>Falcon Viajes.Calle Ferraz, 56. 28008, Madrid</span>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  @import "./../scss/_decimals.scss";
  @import "./../scss/_functions.scss";

  footer {

    border-top: 1px solid #FFFFFF;
    height: auto;
    position: relative;
    width: 100%;
    background: #cf0000;
    box-sizing: border-box;
    padding: 40px 0;

    margin-top: 50px;

    #footer_enlace_mapa, #footer_direccion_falcon {
      width: auto;
      height: 100%;
      display: inline-block;
      vertical-align: top;
    }

    #footer_enlace_mapa {
      width: 420px;
      a {
        img {
          height: auto;
          width: 420px;
        }
      }
    }

    #footer_direccion_falcon {
      margin: 30px 0;
      max-width: 360px;
      color: #FFFFFF;
      box-sizing: border-box;
      padding-left: 20px;
      h3 {
        margin: 0 0 10px;
        font-size: 24px;
        line-height: 1;

      }
      span {
        font-weight: 100;
        font-size: 14px;
        line-height: 1.5;
        display: block;
      }
    }

    @media (min-height:  1100px) { 
      position: fixed;
      bottom: 0;
      
    }

    @media (max-width: 1200px) {


      #footer_direccion_falcon {
        margin: 10px 0 0;
        max-width: 480px;
        padding-left: 20px;
        h3 {
          margin: 0;
          font-size: 24px;
        }
        span {
          font-size: 16px;
        }
      }
    }

    @media (max-width: 1024px) {
      height: auto;
      width: 100vw;
      box-sizing: border-box;
      padding: 15px;
       #footer_direccion_falcon {
         margin: 2px 0 0;
         width: 45vw;
       }
    }

    @media (max-width :767px) {

      padding: 10px 0;
      width: 100%;
      box-sizing: border-box;     
      #footer_enlace_mapa, #footer_direccion_falcon {
      }

      #footer_enlace_mapa {
        margin: 15px;
        width: calc(100% - 30px);
        a {
          img {
            height: auto;
            width: 100%;
          }
        }
      }

      #footer_direccion_falcon {
        margin: 10px 0;
        width: 100vw;
        max-width: 100vw;
        color: #FFFFFF;
        box-sizing: border-box;
        padding-left: 15px;
        h3 {
          margin: 0 0 10px;
          font-size: 16px;
          br {
            display: none;
          }
        }
        span {
          font-size: 12px;
          line-height: 1.5;
        }
      }

      
    }



  }
</style>
