<template>
  <div id="falcon_vue">
    <Cabecera/>
    <Loader v-if="showLoader" />
    <Formulario v-if="!formularioEnviado" @formEnviado="handleFormEnviado"/>
    <Resultados v-else :formulario-data="formularioData"/>
    <Spinner v-if="showSpinner"/>
    <Banner/>
    <Footer />
  </div>
</template>

<script>

import Loader from './components/Loader.vue'
import Cabecera from './components/Cabecera.vue'
import Formulario from './components/Formulario.vue'
import Resultados from './components/Resultados.vue'
import Spinner from './components/Spinner.vue'
import Banner from './components/Banner.vue'
import Footer from './components/Footer.vue'

export default {
  data(){
    return {
      name: 'falcon_vue',
      showLoader: true,
      formularioEnviado: false,
      showSpinner: false,
      formularioData: {}
    }
  },
  components: {
    Loader,
    Cabecera,
    Formulario,
    Resultados,
    Spinner,
    Banner,
    Footer
  },
  mounted: function () {
    window.setTimeout(() => {
      this.showLoader = false
    },3750)
  },
  methods: {
    handleFormEnviado (data) {
      this.formularioData = data
      this.showSpinner = true
      window.setTimeout(() => {
        this.showSpinner = false
        this.formularioEnviado = true
      },5000)
    }
  }
}
</script>

<style lang="scss">

  @import "./scss/_variables.scss";
  @import "./scss/_decimals.scss";
  @import "./scss/_functions.scss";
  @import "./scss/_fonts.scss";


  body{ 
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: Helvetica;
    background-color: #cf0000;
    font-size: 14px;

    .contenedor_central {
      position: relative;
      width: 100%;
      max-width: 860px;
      margin: 0 auto;
    }
    .container {
      width: 100%;
      max-width: 860px;
      margin: 0 auto;
    }

    .caja_sombra {
      box-sizing: border-box;
      padding: 60px 50px 40px;
      -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.5);
      -moz-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.5);
      box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.5);
      background-color:#cf0000;
    }

    .input-v-date-picker {
      width: 100%;
      max-width: 300px;
      background-color: #FFFFFF;
      border: 1px solid #FFFFFF;
      border-radius: 24px;
      font-size: 9px;
      line-height: 24px;
      box-sizing: border-box;
      padding: 0 10px;
      position: relative;
      
      &:after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        top: 7px;
        right: 10px;
        background: url('assets/commons/select-icon.png') no-repeat scroll center center transparent;
        background-size: contain;
        
      }
    }

    h1, h2, h3 {
      font-family: 'HelveticaRoundedLTStd-Bd';
    }
    h2 {
      padding: 0;
      margin: 0;
      font-size: 36px;
    }

    @media (min-height:  1100px) { 
      .contenedor_central {
        position: absolute;
        top: 50%;
        left: 50%;
        @include translate(-50%, calc(-50% - 160px));
      }
    }

    @media (max-width: 1200px) {
      .contenedor_central {
        max-width: calc(1024px - 30px);
      }
      .container {
        max-width: calc(1024px - 30px);
      }
    }


    @media (max-width :767px) {
      font-size: 12px;
      .caja_sombra {
        padding: 20px 10px;
        width: 100%;
        box-sizing: border-box;
      }
      .contenedor_central {
        margin: 50px 15px;
        max-width: calc(100vw - 30px);
      }
      .container {
        
      }
      h2 {
        font-size: 20px;
      }
      .input-v-date-picker {
        width: 100%;
        max-width: 100%;
      }
    }
  }
</style>