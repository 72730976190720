<template>
  <div class="banner">
    <img alt="Banner Falcon viajes by PP" :src="cambiaBanner()">
  </div>
</template>

<script>
export default {
  data() {
    return {
      imagenes: [
        "banner-bennicasin.png",
        "banner-rioja.png",
        "banner-valladolid.png",
        "banner-lanzarote.png",
      ],
      imagenActual: 0
    };
  },
  methods: {
    cambiaBanner() {
      
      setTimeout(() => {
        this.imagenActual = (this.imagenActual + 1) % this.imagenes.length;
      },7000)

      return require('../assets/banners/'+this.imagenes[this.imagenActual]);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "./../scss/_decimals.scss";
  @import "./../scss/_functions.scss";
  .banner {
    position: relative;
    width: 100%;
    max-width: 860px;
    margin: 30px auto 0;
    -webkit-box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.5);
    -moz-box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.5);
    box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.5);
    img{
      display: block;
      max-width: 100%;
    }

    @media (min-height:  1100px) { 
      position: absolute;
      top: 50%;
      left: 50%;
      @include translate(-50%, calc(0% + 100px));
    }

    @media (max-width: 1200px) {
       max-width: 1024px;
       box-sizing: border-box;
      padding: 0px;
    }

    @media (max-width: 1024px) {
      width: calc(100% - 30px);
      max-width: calc(1024px - 64px);
      box-sizing: border-box;
      padding: 0px;
    }

    @media (max-width :767px) {

      width: calc(100% - 30px);
      box-sizing: border-box;
      padding: 0 0;

    }


}
</style>
