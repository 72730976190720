<template>
  <div id="contenedor_formulario" class="contenedor_central">
    <Tabs @TabChanged="handleTabChanged" :tabActive="'falcon'"/>
    <div id="contenedor_formulario_bloque" class="caja_sombra">
      <form action="#" v-on:submit.prevent autocomplete="off" >
        <h2>BUSCADOR DE VUELOS</h2>
        <div class="input_container container_colum">
          
          <label for="selectorigen">ORIGEN</label>
          <div class="select_block">
            <select id="selectorigen" v-model="selectedValues.origenSelected" v-on:change="onChangeOrigen($event)">
              <option v-for="origen in origenes" :key="origen">{{ origen }}</option>
            </select>
            <span class="fake_select">{{ fakeSelect.origen }}</span>
          </div>
        </div>
        <div class="input_container container_colum right_colum">
          <label for="selectdestino">DESTINO</label>
          <div class="select_block">
            <select id="selectdestino" v-model="selectedValues.destinoSelected" v-on:change="onChangeDestino($event)">
              <option v-for="destino in destinos" :key="destino">{{ destino }}</option>
            </select>
            <span class="fake_select">{{ fakeSelect.destino }}</span>
          </div>
        </div>
        <div class="input_container container_colum">
          <label for="inputsalida">FECHA SALIDA</label>
          <!-- <input type="date" id="inputsalida" v-model="selectedValues.fechaSalidaSelected"> -->

          <v-date-picker v-model='selectedValues.fechaSalidaSelected' show-caps :min-date='new Date()'  :input-props='{ name:"fechaLlegadaDatePicker", class: "input-v-date-picker", placeholder: "DD/MM/YYYY", readonly: true }'> </v-date-picker>
        </div>
        <div class="input_container container_colum right_colum">
          <label for="inputllegada">FECHA LLEGADA</label>
          <!-- <input type="date" id="inputllegada" v-model="selectedValues.fechaLlegadaSelected">-->

          <v-date-picker v-model='selectedValues.fechaLlegadaSelected' show-caps :min-date='new Date()'  :input-props='{ name:"fechaLlegadaDatePicker", class: "input-v-date-picker", placeholder: "DD/MM/YYYY", readonly: true }'> </v-date-picker>
          
        </div>
        <div class="input_container container_colum container_last">
          <ul class="radio_block">
            <li class="radio_input" v-for="item in quienSoy" :key="item">
              <input :id="item" type="radio" v-model="selectedValues.quienSoySelected" :value="item">
              <label :for="item">{{ item }}</label>
            </li>
          </ul>
        </div><!--
        --><div class="input_container container_colum container_last right_colum">
          <button @click="buscaVuelos">BUSCAR VUELOS</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Tabs from './Tabs.vue'

export default {
  data() {
    return {
      origenes: [
        "Madrid (Base de Cuatro Vientos)",
        "Madrid (Base de Torrejón)",
        "Barcelona",
        "Valencia",
        "Sevilla",
        "Lanzarote"
      ],
      destinos: [
        "Castellón",
        "Valladolid",
        "París",
        "Bruselas",
        "Nueva York",
        "Ciudad de México"
      ],
      quienSoy: [
        "Soy Pedro Sánchez o un familiar",
        "Soy un ciudadano de a pie"
      ],
      selectedValues: {
        origenSelected: "",
        destinoSelected: "",
        fechaSalidaSelected:   new Date() ,
        fechaLlegadaSelected:   new Date(),
        quienSoySelected: "",
        tabActive: "falcon"
      },
      fakeSelect: {
        origen: "Selecciona el origen",
        destino: "Selecciona el destino"
      }
    };
  },
  components: {
    Tabs
  },
  computed: {},
  methods: {
    onChangeOrigen(event) {
      console.log(event.target.value);
      this.fakeSelect.origen = event.target.value;
    },
    onChangeDestino(event) {
      console.log(event.target.value);
      this.fakeSelect.destino = event.target.value;
    },
    buscaVuelos() {
      if(this.validaForm()){
        this.$emit('formEnviado', this.selectedValues);
      }
      
    },
    validaForm() {
      let valido = true;
      for (const key in this.selectedValues) {
        if( this.selectedValues[key] === ""){
          valido = false;
        }
      }
      return valido;
    },
    handleTabChanged (tabActive) {
      this.selectedValues.tabActive = tabActive;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "./../scss/_decimals.scss";
  @import "./../scss/_functions.scss";

  #contenedor_formulario {

    #contenedor_formulario_bloque {


      form {
        h2 {
          color: #FFFFFF;
        }
        
        .input_container {
          vertical-align: top;
          margin-top: 35px;
          display: inline-block;
          width: 100%;
        
          label {
            font-weight: 100;
            margin-bottom: 5px;
            color: #FFFFFF;
            display: block;
            font-size: 14px;
            text-transform: uppercase;
          }
          
          input, select, button {
            outline: 0;
          }

          .select_block {
            width: 300px;
            max-width: 100%;
            position: relative;
            height: 24px;
            select, .fake_select {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }
            select {
              opacity: 0; 
              z-index: 2;
              text-transform: uppercase;
            }
            .fake_select {
              background-color: #FFFFFF;
              border-radius: 24px;
              font-size: 9px;
              line-height: 24px;
              box-sizing: border-box;
              padding: 0 10px;
              text-transform: uppercase;
              &:after {
                
                content: "";
                position: absolute;
                width: 8px;
                height: 8px;
                top: 7px;
                right: 10px;
                background: url('../assets/commons/select-icon.png') no-repeat scroll center center transparent;
                background-size: contain;
              }
            }
          }

          &.container_colum {
            display: inline-block;
            width: 50%;
          }

          &.right_colum {
            max-width: 300px;
            margin-left: 80px;

            label {
              max-width: 300px;
            }
          }

          &.container_last {
            margin-top: 80px;
          }

          .radio_block {
            padding: 0;
            .radio_input {
              margin-top: 5px;
              list-style: none;
              label, input {
                margin-bottom: 0px;
                display: inline;
              }
              input {
                position: relative;
                &:after {
                  content: "\e902";
                  font-family: 'icomoon' !important;
                  speak: none;
                  font-style: normal;
                  font-weight: normal;
                  font-variant: normal;
                  text-transform: none;
                  line-height: 1;
                  /* Better Font Rendering =========== */
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 18px;
                  height: 18px;
                  font-size: 24px;
                  z-index: 9;
                  background-color:#cf0000;
                  color: #FFFFFF;
                  @include translate(-8px, -8px);
                  padding: 2px 2px;
                }
                &:checked {

                  &:after {
                    content: "\e901";
                  }
                }
              }
              label {
                text-transform: none;
                margin-left: 10px;
              }
              &:last-child {
                margin-top: 15px;
              }
            }
          }
          
          button {
            min-width: 300px;
            margin-top: 10px;
            background-color: #ffef2d;
            border: 2px solid  #ffef2d;
            border-radius: 48px;
            font-family: 'HelveticaRoundedLTStd-Bd';
            color: #000000;
            font-size: 28px;
            height: 56px;
            line-height: 56px;
            padding: 0 20px;
            &:hover {
              border: 2px solid #000000;
              background-color: #000000;
              color: #ffef2d;
              cursor: pointer;
            }
          }
        }
      }
    }

    @media (min-height:  1100px) { 
      z-index: 9;
      position: absolute;
      top: 50%;
      left: 50%;
      @include translate(-50%, calc(-50% - 180px));
    }

    @media (max-width: 1200px) {
  
      padding: 15px 40px;
      #contenedor_formulario_bloque {
      
        form {

          h2 {
            margin-bottom: 10px;
          }
          .input_container {
            &.right_colum {
              margin-left: 2%;

              label {
                max-width: 300px;
              }
            }
            .radio_block {

              label {
                font-size: 18px;
              }
            }

            button {
              
              line-height: 42px;
            }
          }
        
        }
     
      } 
    
    }

    @media (max-width: 1024px) {
      width: 100vw;
      box-sizing: border-box;
      padding: 15px;
      #contenedor_formulario {
        #contenedor_formulario_bloque { 
          form {
            .input_container {
              .radio_block {
                .radio_input label {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    @media (max-width :767px) {

      box-sizing: border-box;
      width: 100vw;
      margin: 0 auto;

      #contenedor_formulario_bloque {

        form {

          h2 {
            margin-bottom: 10px;
          }
          .input_container {

            &.container_colum {
              display: block;
              width: 100%;
            }

            &.container_last {
              text-align: center;
            }

            &.right_colum {
              max-width: 100%;
              margin-left: 0;

              label {
                max-width: 100%;
              }
            }

            input[type="date"] {
              width: 100%;
              max-width: 100%;
            }

            .select_block {
              width: 100%;
            }

            
            .radio_block {
              text-align: left;
              label {
                font-size: 14px;
              }
            }

            button {
              min-width: 100%;
              margin: 0 auto 15px;
              line-height: 56px;
              font-size: 26px;
            }
          }
        
        }
     
      } 

    }


  }

</style>
