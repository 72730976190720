<template>
  <div class="contenedor_central" id="contenedor_resultados_wrapper">
    <Tabs @TabChanged="handleTabChanged" :tabActive="formularioData.tabActive"/>
      <div id="contenedor_resultados" class="caja_sombra">
      <h2>
        VUELO EN
        <span v-if="formularioData.tabActive == 'falcon'">FALCON</span>
        <span v-else>SUPER PUMA</span>
      </h2>
      <h3>{{ formularioData.origenSelected }} - {{ formularioData.destinoSelected }}</h3>
      
      <div class="share_block">
        <span>Compártelo</span>
        <a class="link-rrss-icono share-tw uri_encode" :href="`https://twitter.com/home?status=${shareText.twitter} ${sharePath.encode}`" target="_blank"><i class="icon-icono-tw"></i></a>
        <a class="link-rrss-icono share-fb" :href="`https://www.facebook.com/sharer/sharer.php?u=${sharePath.noencode}`" target="_blank"><i class="icon-icono-fb"></i></a>
        <a class="link-rrss-icono share-ws uri_encode" :href="`whatsapp://send?text=${sharePath.encode}`" target="_blank"><i class="icon-icono-whatsapp"></i></a>
      </div>
      
      <h4>IDA Y VUELTA</h4>
      <p class="ida_vuelta">
        IDA
        <span
          v-if="formularioData.quienSoySelected == 'Soy Pedro Sánchez o un familiar'"
        >PARA SANCHEZ</span>
        <span
          class="precio"
          v-if="formularioData.quienSoySelected == 'Soy Pedro Sánchez o un familiar'"
        >0€</span>
        <span class="precio frase_precio" v-else >
          <span v-if="formularioData.tabActive == 'falcon'">{{ frasesAleatoria[0] }}</span>
          <span v-else>{{ frasesAleatoria[1] }}</span>          
        </span>
      </p>

      <p class="ida_vuelta">
        VUELTA
        <span
          v-if="formularioData.quienSoySelected == 'Soy Pedro Sánchez o un familiar'"
        >PARA SANCHEZ</span>
        <span
          class="precio"
          v-if="formularioData.quienSoySelected == 'Soy Pedro Sánchez o un familiar'"
        >0€</span>
        <span class="precio frase_precio" v-else >
          <span v-if="formularioData.tabActive == 'falcon'">{{ frasesAleatoria[2] }}</span>
          <span v-else>{{ frasesAleatoria[3] }}</span>          
        </span>
      </p>
      <div class="buscaotro_row">
        <a class="busca_otro" @click="buscaOtro()">Buscar otro vuelo</a>
        <a class="soy_buscaotro"
          @click="cambiaQuien('Soy un ciudadano de a pie')"
          v-if="formularioData.quienSoySelected == 'Soy Pedro Sánchez o un familiar'"
        >¿Cuánto costaría de verdad?</a>
        <a class="soy_buscaotro soy_buscaotro_pedro"
          @click="cambiaQuien('Soy Pedro Sánchez o un familiar')"
          v-else
        >¿Cuánto le costaría a Pedro Sánchez?</a>
      </div>
      <div class="total">
        <span>TOTAL</span>
        <span
          class="resultado"
          v-if="formularioData.quienSoySelected == 'Soy Pedro Sánchez o un familiar'"
        >PAGAMOS TODOS</span>
        <span class="resultado resultado_pagamos" v-else>PAGAMOS TODOS</span>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "./Tabs.vue";
export default {
  data() {
    return {
      frases: [
        'Ni lo pienses',
        'Te da la risa',
        'Ni ahorrando toda la vida',
        'Inviable',
        'Un pastizal',
        'Ufffffff'
      ],
      sharePath: {
        encode : encodeURIComponent(window.location.href),
        noencode : window.location.href
      },
      shareText: {
        twitter : encodeURIComponent("Descubre cuánto nos cuesta el capricho de Sánchez de usar el Falcon o el SuperPuma #FalconViajes ")
      }
    };
  },
  props: {
    formularioData: Object
  },
  components: {
    Tabs
  },
  methods: {
    buscaOtro() {
      location.reload();
    },
    cambiaQuien(quien) {
      this.formularioData.quienSoySelected = quien;
    },
    handleTabChanged(tabActive) {
      this.formularioData.tabActive = tabActive;
    }
  },
  computed: {
    frasesAleatoria() {
      let frasesAleatoriasArr = [];
      let copiaFrases = this.frases;
      let alea;

      for (let index = 0; index < 4; index++) {
        
        alea = Math.floor(Math.random() * copiaFrases.length);
        frasesAleatoriasArr.push(copiaFrases[alea]);
        copiaFrases.splice(alea, 1);
        
      }


      
      return frasesAleatoriasArr
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import "./../scss/_decimals.scss";
  @import "./../scss/_functions.scss";
  #contenedor_resultados {
    position: relative;
    padding-left:75px;
    padding-right: 75px;
    *{
      color: #fff;
    }
    
    h2 {
      margin: 0;
    }
    
    h3 {
      margin: 0;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 100;
      font-size: 26px;
      text-transform: uppercase;
    }
    h4 {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 100;
      font-size: 14px;
      text-transform: uppercase;      
      margin:  50px 0;
    }

    .share_block {
      position: absolute;
      top: 60px;
      right: 70px;
      text-align: right;
      span {
        font-size: 10px;
        font-weight: 100;
        display: block;
        text-transform: uppercase;
      }
      
      a {
        @include translate(5px, 0);
        position: relative;
        display: inline-block;
        text-decoration: none;
        &.share-ws {
          display: none;
        }
        i {
          font-size: 34px;
        }
      }
    }

    .ida_vuelta {
      margin: 0 0 30px;
      vertical-align: bottom;
      line-height: 24px;

      .precio {

        float: right;
        font-size: 36px;
        font-family: 'HelveticaRoundedLTStd-Bd';
        &.frase_precio {
            
        }
      }

    }


    .buscaotro_row {
      padding-top: 25px;
      display: block;
      .busca_otro{
        font-weight: 100;
        text-transform: uppercase;
        cursor: pointer;
        text-decoration: underline;
      }
      .soy_buscaotro{
        text-decoration: underline;
        float: right;
        cursor: pointer;
        &.soy_buscaotro_pedro{

        }
      }
    }
    .total {
      box-sizing: border-box;
      width: 110%;
      margin-top: 20px;
      background-color: #ffef2d;
      border: 2px solid  #ffef2d;
      border-radius: 30px;
      font-family: 'HelveticaRoundedLTStd-Bd';
      color: #000000;
      font-size: 30px;
      height: 58px;
      line-height: 62px;
      padding: 0 40px;
      @include translate(-5%,0);
      span {
         color: #000000;
      }
      .resultado {
        float: right;
        color: #000000;
        font-size: 20px;
        width: 100px;
        line-height: 25px;
        margin-top: 6px;
        text-align: right;
        &.resultado_pagamos {
           color: #000000;
        }
      }
    }

    @media (min-height:  1100px) { 

      #contenedor_resultados {
        z-index: 9;
        position: absolute;
        top: 50%;
        left: 50%;
        @include translate(-50%, calc(-50% - 160px));
      }

    }

    @media (max-width: 1200px) {
      width: calc(100% - 30px);
      margin: 0 15px;
    }

    @media (max-width :767px) {
      width:100%;
      margin: 0px;
      padding-left: 15px;
      padding-right: 15px;

      h2 {
        font-size: 20px;
      }
      h3 {
        font-size: 14px;
      }
      h4 {
        margin-top: 20px;
      }
      .share_block {
        margin: 30px 0 0;
        display: block;
        position: relative;
        right: unset;
        right: initial;
        top: unset;
        top: initial;
        text-align: right;
        span {
          font-size: 15px;
        }
        
        a {
          margin-left: 0px;
          position: relative;
          &.share-ws {
            display: inline-block!important;
          }
          i {
            font-size: 32px;
          }
        }
      }

      .ida_vuelta {
        margin: 0 0 30px;
        vertical-align: bottom;
        line-height: 24px;

        .precio {

          font-size: 20px;
          &.frase_precio {
              
          }
        }

      }


      .buscaotro_row {
        .busca_otro{
          display: block;
        }
        .soy_buscaotro{
          margin-top: 20px;
          display: block;
          float: left;
          &.soy_buscaotro_pedro{

          }
        }
      }

      .total {

        width: 100%;
        margin-top: 60px;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        @include translate(0, 0);
        .resultado {
          font-size: 14px;
          line-height: 15px;
          margin-top: 10px
        }
        > span {
        }
      }


    }


  }



</style>
