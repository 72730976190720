<template>
  <div id="contenedor_formulario_tabs">
    <ul>
      <li :class="{active: actualTab=='falcon'}" @click="changeTabActive('falcon')">
        <span>
          <i class="icon-icono-avion"></i>
          Falcon
        </span>
      </li>
      <li :class="{active: actualTab=='superPuma'}" @click="changeTabActive('superPuma')">
        <span>
          <i class="icon-icono-helicoptero"></i>
          Super Puma
        </span>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  data() {
    return {
      actualTab: this.tabActive
    };
  },
  props:{
      tabActive: String
  },
  methods: {
    changeTabActive(value){
      this.actualTab = value
      this.$emit('TabChanged', this.actualTab);
    }
  }
};
</script>

<style scoped lang="scss">
    @import "./../scss/_decimals.scss";
    @import "./../scss/_functions.scss";
    #contenedor_formulario_tabs {

        ul {
            display: inline-block;
            padding: 0;
            margin: 0;
            
            li {
                min-width: 250px;
                cursor: pointer;
                margin-left: 5px;
                list-style: none;
                display: inline-block;
                /*
                border-top: 1px solid #FFFFFF;
                border-right: 1px solid #FFFFFF;
                border-bottom: 0px none;
                border-left: 1px solid #FFFFFF;
                */
                
                box-sizing: border-box;
                padding: 6px 24px 2px 0;
                
                -webkit-border-top-left-radius: 10px;
                -webkit-border-top-right-radius: 10px;
                -moz-border-radius-topleft: 10px;
                -moz-border-radius-topright: 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                
                -webkit-box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.25);
                -moz-box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.25);
                box-shadow: 0px -1px 5px 0px rgba(0,0,0,0.25);

                position: relative;
                background-color:#cf0000;
                opacity: 1;
                
                &:first-child {
                    margin-left: 0;
                    &.active {

                    }
                    &:after {
                      left: 0px;
                    }
                }
            
                &:last-child {
                  @include translate(-20px, 0);
                  padding-left: 20px;
                  min-width: 280px;
                  &.active {
                    
                  }
                }

                &:after {
                    opacity: 0;
                    content: " ";
                    position: absolute;
                    left: -10px;
                    top: 100%;
                    width: 110%;
                    height: 20px;
                    background-color:#cf0000;
                    @include translate(0, 0px);
                }
                &:before {
                    opacity: 0;
                    content: " ";
                    position: absolute;
                    left: 0px;
                    top: 100%;
                    width: 100%;
                    height: 20px;
                    background-color:#cf0000;
                    @include translate(0, -10px);
                }

                &.active {
                  -webkit-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.5);
                  -moz-box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.5);
                  box-shadow: 0px 0px 15px 2px rgba(0,0,0,0.5);
                  background-color:#cf0000;
                  opacity: 1;
                  z-index: 9;
                  &:after, &:before {
                      opacity: 1;
                  }
                  
                }

                span {
                    vertical-align: top;
                    font-size: 14px;
                    line-height: 28px;
                    color: #FFFFFF;
                    cursor: pointer;
                    text-transform: uppercase;
                    i {
                        font-size: 36px;
                        line-height: 24px;
                        color: #FFFFFF;
                    }
                }
            }
        }
        @media (max-width :1200px) {
          margin: 0;
          
        }
        @media (max-width :767px) {
          margin: 0;
          ul {
            
            li {
                min-width:145px;
                width:145px;
                padding: 0px 24px 0px 0;
                
                &:first-child {
                  
                }
            
                &:last-child {
                  min-width:145px;
                  width:145px;
                }

                &:after {
                  
                }

                &.active {
                  

                    &:after {
                      
                    }
                }

                span {
                  font-size: 10px;
                  line-height: 28px;
                  i {
                      font-size: 28px;
                      line-height: 28px;
                      color: #FFFFFF;
                  }
                }
            }
          }

        }

         @media (max-width :350px) {
           ul {
            
            li {
                min-width:125px;
                width:125px;
                padding: 0px 24px 0px 0;
                > span {
                  font-size: 9px;
                }
                &:first-child {
                  
                }
            
                &:last-child {
                  min-width:125px;
                  width:125px;
                  padding-right: 0px;
                }
              }
           }
         }


    }
</style>
