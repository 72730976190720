import Vue from 'vue'
import App from './App.vue'
import VCalendar from 'v-calendar';
import 'v-calendar/lib/v-calendar.min.css';



// Use v-calendar, v-date-picker & v-popover components
Vue.use(VCalendar, {
    firstDayOfWeek: 2 // Monday
});

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#falcon_vue')