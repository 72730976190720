<template>
  <header>
    <h1>
      <a class="logo_link" @click="logoLink()"><img id="logo_falcon" alt="Falcon viajes by PP" src="../assets/commons/logo-falcon@2x.png"></a>
    </h1>
    <img id="logo_by_pp" alt="By PP logo" src="../assets/commons/logo-juventudes@2x.png">
  </header>
</template>

<script>
export default {
  methods: {
    logoLink() {
      location.reload();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  @import "./../scss/_decimals.scss";
  @import "./../scss/_functions.scss";

  header {
    height: 80px;
    position: relative;
    width: 100%;
    background:  #cf0000;
    z-index: 9999;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.50);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.50);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.50);

    margin-bottom: 50px;
    
    h1 {
      height: 100%;
      margin: 0;
      float: left;
      display: block;
      #logo_falcon {
        margin: 10px 0 0 50px;
        vertical-align: top;
        max-height: 80%;
        width: auto;
        cursor: pointer;
      }
    }
    #logo_by_pp {
      box-sizing: border-box;
      padding: 10px 0;
      height: 100%;
      width: auto;
      float: right;
      margin: 0 50px 0 0;
    }

    @media (max-width: 1200px) {
      height: 125px;
      h1 { 
        #logo_falcon {
          margin: 15px 0 0 50px;

        }
      }
      #logo_by_pp {
      }
    }
    
    @media (max-width: 1024px) {
      height: 85px;
      h1{ 
        float: left;
        #logo_falcon {
          margin: 15px;
          
        }
      }
    }

    @media (max-width :767px) {
      margin-bottom: 10px;
      height: 60px;
      h1{ 
        float: left;
        #logo_falcon {
          margin: 5px 0 0 5px;
          img {
            
          }  
        }
      }
      #logo_by_pp {
        float: right;
        margin: 0 5px 0 0;
        
      }

    }


    @media (max-width :350px) {
      margin-bottom: 10px;
      height: 60px;
      h1{ 
        float: left;
        #logo_falcon {
          margin: 10px 0 0 5px;
          width: 45vw;
          height: auto;
        }
      }
      #logo_by_pp {
        float: right;
        margin: 0px 5px 0 0;
        width: 40vw;
        height: auto;
      }
   }


  }

   

</style>
